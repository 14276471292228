export default {
	// baseUrl: "http://dowelltech.wjruanjian.com",
	// baseUrl: "http://www.mingpinhui.cc",
	// baseUrl: "http://www.tczg.xyz/"
	// baseUrl: "https://lql.hlzmz.cn/api.php/",
	// baseUrlimg: "https://lql.hlzmz.cn"
	// baseUrl: "https://www.yidaoguoxue.cn/api",
	// baseUrlimg:'https://www.yidaoguoxue.cn/'
	baseUrl: "https://yfweb.wjruanjian.com/api",
	baseUrlimg:'https://yfweb.wjruanjian.com/'
}